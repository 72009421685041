import {Pipe, PipeTransform} from '@angular/core'
import {
  DefaultLanguage,
  TLanguageCode,
  TTranslationItem
} from '../i18n/i18n.service'
import {
  CLASSIC_HINGES,
  LUCKA_DJUPADAL,
  LUCKA_HOGALID, LUCKA_MALMGARDEN, LUCKA_MELLANHEDEN,
  LUCKA_RONNEHOLM, LUCKA_SORGENFRI, LUCKA_SUNNANA, MODERN_HINGES
} from '../model/model-types'

const TranslateMap = new Map<string, TTranslationItem>([
  /**
   * Units
   */
  ['pcs', {en: 'pcs', sv: 'st'}],
  /**
   * Translation for Cornice
   */
  ['cornice', {en: 'Cornice', sv: 'Krönlist'}],
  /**
   * Translation for socle
   */
  ['socle', {en: 'Socle board', sv: 'Sockel'}],
  /**
   * Here are the cornice styles
   */
  ['regular', {en: 'Regular', sv: 'Vanlig'}],
  ['funkis', {en: 'Funkis', sv: 'Funkis'}],
  ['non-standard', {en: 'Non-standard', sv: 'Special'}],
  ['outside', {en: 'Outside', sv: 'Utanpåliggande'}],
  /**
   * Here are the socle styles
   */
  ['regularSocle', {en: 'Regular plain socle board', sv: 'Vanlig'}],
  ['outsideSocle', {en: 'Outside skirting board', sv: 'Utanpåliggande'}],
  ['nonStandardSocle', {en: 'Non-standard socle board', sv: 'Icke standard'}],
  /**
   * Translation for Door
   */
  ['door', {en: 'Door', sv: 'Lucka'}],
  /**
   * Here are the door styles
   */
  [LUCKA_HOGALID, {en: 'Door type 1 Högalid', sv: 'Högalid'}],
  [LUCKA_DJUPADAL, {en: 'Door type 2 Djupadal', sv: 'Djupadal'}],
  [LUCKA_RONNEHOLM, {en: 'Door type 3 Rönneholm', sv: 'Rönneholm'}],
  [LUCKA_SUNNANA, {en: 'Door type 4 Sunnanå', sv: 'Sunnanå'}],
  [LUCKA_MALMGARDEN, {en: 'Door type 5 Malmgården', sv: 'Malmgården'}],
  [LUCKA_SORGENFRI, {en: 'Door type 6 Sorgenfri', sv: 'Sorgenfri'}],
  [LUCKA_MELLANHEDEN, {en: 'Door type 7 Mellanheden', sv: 'Mellanheden'}],
  /**
   * Here are the hinge types
   */
  ['none', {en: 'No hinges', sv: 'No hinges'}],
  [CLASSIC_HINGES, {
    en: 'Standard old fashioned hinges',
    sv: 'Klassiskt lyftgångjärn'
  }],
  [MODERN_HINGES, {
    en: 'Blum softclose hinges',
    sv: 'Moderna mjukstängande gångjärn (Blum, "osynliga")'
  }],
  /**
   * Here are the door directions
   */
  ['left', {en: 'Left hinged', sv: 'Vänsterhängd'}],
  ['right', {en: 'Right hinged', sv: 'Högerhängd'}],
  ['top', {en: 'Top hinged', sv: 'Topphängd'}],
  ['bottom', {en: 'Bottom hinged', sv: 'Nederhängd'}],
  /**
   * Drawer front no drawer box
   */
  ['drawerFrontNoDrawerBox', {
    en: 'Drawer front (no drawer box)',
    sv: 'Lådfront (utan låda)'
  }],
  /**
   * Drawer front
   */
  ['drawerFront', {
    en: 'Drawer front with drawer box',
    sv: 'Här behövs en översättning från Adam'
  }],
  ['drawerBox', {en: 'Drawer box', sv: 'Här behövs en översättning från Adam'}],
  /**
   * Plate rack
   */
  ['plateRack', {en: 'Plate rack', sv: 'Tallriksställ'}],
  ['brass', {en: 'Brass', sv: 'Mässing'}],
  ['stainless', {en: 'Stainless', sv: 'Rostfritt'}],
  ['vGroove', {en: 'V-groove back', sv: 'V-spontat bakstycke'}],
  ['other', {en: 'Other', sv: 'Annat'}],
  /**
   * Board
   */
  ['board', {en: 'Board', sv: 'Träskiva/passbit'}],
  ['ash', {en: 'Ash', sv: 'Ask'}],
  ['oak', {en: 'Oak', sv: 'Ek'}],
  ['pine', {en: 'Pine', sv: 'Tall'}],
  /**
   * Paint Process
   */
  ['1', {
    en: 'Standard, linseed paint process - Our painter do the last coat',
    sv: 'Linoljefärg målad i snickeriet'
  }],
  ['2', {
    en: 'Standard, linseed paint process - The customer do the last coat',
    sv: 'Linoljefärg, komplett (två strykningar i snickeriet, en strykning efter montering)'
  }],
  ['3', {
    en: 'Standard linseed paint process (Will get last coat on location after fitting)',
    sv: 'Linoljefärg, grundmålning (kunden slutmålar själv)'
  }],
  ['4', {en: 'Unpainted/untreated', sv: 'Helt omålat/obehandlat'}],
  ['5', {
    en: 'Regular paint - NOT LINSEED PAINT - FINISHED in carpentry',
    sv: 'Vanlig färg (ej linoljefärg), målad i snickeriet'
  }],
  ['6', {
    en: 'Regular paint - NOT LINSEED PAINT. (Will get last coat on location after fitting)',
    sv: 'Vanlig färg (ej linoljefärg). Grundmålning i snickeriet, kunden slutmålar själv.'
  }],
  ['7', {en: 'Conventional paint - complete', sv: 'Vanli farg'}],
  ['8', {en: 'Unpainted but waxed insides', sv: 'Omålat med vaxade insidor'}]
])

@Pipe({
  name: 'translateExtra'
})
/**
 * Pipe used for Factory Extras only (or that should be the case).
 * There are options that are very similar, or even the same, as CabinetOptions,
 * which are configured through Settings > Cabinet Options.
 * Ideally, we should have "Factory Extras Options" inside Settings too.
 * But until we do, we will use this separate pipeline.
 */
export class TranslateExtraPipe implements PipeTransform {
  transform(value: string, lc: TLanguageCode = DefaultLanguage): string {
    const res = TranslateMap.get(value)
    if (res) {
      return res[lc]
    }
    return value
  }
}
